<template>
    <div class="root">
        <headers></headers>
        <div class="top">
            <div>
                <h1>{{ $t('home.title1') }}<span>AI</span></h1>
                <h1>{{ $t('home.title2') }}</h1>
                <p class="desc">{{ $t('home.desc') }}</p>
            </div>
            <div class="buts-lay">
                <div class="buts">
                    <button @click="goPage('lp')">{{ $t('home.lp') }}</button>
                    <button @click="toWhitePaper">{{ $t('home.whitepaper') }}</button>
                </div>
                <img src="../../static/images/home-top-bg.png" />
            </div>
            <img class="bg-pc" src="../../static/images/home-top-bg-pc.png" />
        </div>
        <!-- 分享 -->
        <div class="share-lay">
            <div @click="toURL('https://t.me/LABEFCC')">
                <img src="../../static/images/telegram.png" />
                <span>Telegram</span>
            </div>
<!--            <div @click="toURL()">-->
<!--				<img src="../../static/images/twitter.png" />-->
<!--				<span>Twitter</span>-->
<!--			</div>-->
<!--            <div @click="toURL()">-->
<!--				<img src="../../static/images/discord.png" />-->
<!--				<span>Discord</span>-->
<!--			</div>-->
<!--            <div @click="toURL()">-->
<!--				<img src="../../static/images/youtube.png" />-->
<!--				<span>Youtube</span>-->
<!--			</div>-->
        </div>
        <!-- 收益 -->
        <div class="earnings mobile">
            <h3 class="title">{{ $t('home.earningTitle') }}</h3>
            <div class="content" v-for="item in 3" :key="item">
                <img v-if="item==1" src="../../static/images/principle.png" />
                <img v-if="item==2" src="../../static/images/benefit.png" />
                <img v-if="item==3" src="../../static/images/develop.png" />
                <h4>{{ $t(`home.earning.title${item}`) }}</h4>
                <p>{{ $t(`home.earning.desc${item}`) }}</p>
            </div>
        </div>
        <div class="earnings pc">
            <h3 class="title">{{ $t('home.earningTitle') }}</h3>
            <div class="content" :class="item==1?'pc-content1':''" v-for="item in 3" :key="item">
                <img v-if="item==1" src="../../static/images/principle.png" />
                <img v-if="item==2" src="../../static/images/benefit.png" />
                <img v-if="item==3" src="../../static/images/develop.png" />
                <div>
                    <h4>{{ $t(`home.earning.title${item}`) }}</h4>
                    <p>{{ $t(`home.earning.desc${item}`) }}</p>
                </div>
            </div>
        </div>
        <!-- 价值 -->
        <div class="value">
            <h3>{{ $t('home.valueTitle') }}</h3>
            <div class="content-lay">
                <div ref="valueContent" :style="content" class="content" v-for="item in 6" :key="item">
                    <img :src="require('../../static/images/value'+ item +'.png')" />
                    <h4>{{ $t(`home.value.title${item}`) }}</h4>
                    <p>{{ $t(`home.value.desc${item}`) }}</p>
                </div>
            </div>
        </div>
        <!-- 合作伙伴 -->
        <div class="friend">
            <h3>{{ $t('home.friendTitle') }}</h3>
            <img src="../../static/images/partner.png" />
            <p class="copyright">© 2023 ID LABS. All rights reserved.</p>
        </div>
    </div>
</template>
<script setup>
import headers from '@/components/headers'
import {ref, onMounted, reactive, watch, computed, onBeforeUnmount } from 'vue';
import {memberStore} from "@/store/member"
import {walletStore} from "@/store/wallet"
import {webStore} from "@/store/web"
import i18n from "@/i18n";
import {ElMessage} from "element-plus";
import {useRouter} from "vue-router";
import request from "@/api/request";


const mStore = memberStore()
const wStore = walletStore()
const wbStore = webStore()

const router = new useRouter()

const content = reactive({})
const valueContent = ref()
onMounted(()=>{
    window.addEventListener('resize', setHeight)
    setHeight()
})
const setHeight = () =>{
    const obj = {
        height: valueContent.value[0].clientWidth * 0.58 + 'px'
    }
    Object.assign(content,obj)
}

function toURL(url) {
	if (url) {
		window.open(url, '_blank')
	} else {
		ElMessage({
			message: i18n.global.t('alert.soon'),
			type: 'warning'
		})
	}
}

function goPage(url){
	router.push({
		path: url
	})
}

function toWhitePaper() {
	let language = localStorage.getItem('locale') || 'cn' ;
	let url = 'https://raw.githubusercontent.com/idlabs-space/whitepaper/main/IDLABS-whitepaper-en.pdf'
	if (language === 'cn') {
		url = 'https://raw.githubusercontent.com/idlabs-space/whitepaper/main/IDLABS-whitepaper-zh.pdf'
	}
	window.open(url, '_blank')
}

const curAccount = computed(()=>{
	return wStore.curAccount
})
watch(curAccount, (newVal, oldVal) =>{
	if (newVal) {
		if (oldVal) {
			alert(i18n.global.t('alert.reLogin'))
		}

		wbStore.accountLogin().then((result) => {
			wbStore.setLoginState(true)
			mStore.queryMemberInfo()

		}).catch((reason) => {
			wbStore.setLoginState(false)
			console.error('login failed')
		})
	}
})

onBeforeUnmount(()=>{
    window.removeEventListener('resize', setHeight)
})
</script>
<style scoped>
    .root{
        color: white;
        padding-bottom: 70px;
        background: linear-gradient(90deg, #172929 0%, #0D1113 50%, #0E152B 100%);
    }
    h1{
        width: calc(100% - 60px);
        margin: 0 auto;
        font-size: 24px;
    }
    h1 span{
        color: #20EEA4;
    }
    .desc{
        width: calc(100% - 60px);
        margin: 15px auto 30px;
        font-size: 12px;
        color: #BECFD2;
        line-height: 24px;
    }
    .buts-lay{
        position: relative;
        height: 289px;
    }
    .buts{
        /* 地址 级别 直推 团队 个人 */
        width: 100%;
        height: 289px;
        position: relative;
        z-index: 2;
        /* background-image: url(../../static/images/home-top-bg.png);
        background-size: cover; */
        display: flex;
        flex-direction: column;
        margin-top: 40px;
    }
    .buts-lay img{
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
    }
    .buts button{
        width: 145px;
        height: 40px;
        margin-left: 30px;
        margin-bottom: 20px;
        background-color: #20EEA4;
        font-weight: bold;
        border-radius: 4px;
    }
    .buts button:last-child{
        color: #20EEA4;
        background-color: #16483A;
    }
    .share-lay{
        width: calc(100% - 60px);
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
    }
    .share-lay img{
        width: 32px;
    }
    .share-lay div{
        display: flex;
        align-items: center;
        font-size: 12px;
    }
    .share-lay span{
        display: none;
        margin-left: 5px;
    }
    .earnings{
        width: 100%;
        background-image: url(../../static/images/Launching-pad-bg.png);
        background-size: 100% 100%;
        padding-bottom: 30px;
    }
    .earnings .title{
        margin-top: 30px;
        padding-top: 30px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        background: linear-gradient(299deg, #40FFF4 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .earnings .content{
        width: calc(100% - 60px);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
        background-color: rgba(25,67,70,.7);
        border-radius: 24px;
        padding-bottom: 20px;
    }
    .earnings .content img{
        width: 64px;
        height: 64px;
        margin-top: -15px;
    }
    .earnings .content p{
        font-size: 12px;
        padding: 0 30px;
        color: #BECFD2;
        line-height: 20px;
        margin: 0;
    }
    .value{
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        background: linear-gradient(90deg, #172929 0%, #0D1113 50%, #0E152B 100%);
    }
    .value h3{
        text-align: center;
        font-size: 18px;
        margin-bottom: 50px;
        background: linear-gradient(299deg, #20EEA4 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .value .content-lay{
        width: 100%;
    }
    .value .content{
        width: calc(100% - 60px);
        margin: 0 auto 40px;
        background-color: rgb(36,40,41);
        border-radius: 23px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0 20px;
    }
    .value h4{
        color: #20EEA4;
        margin: 0;
        font-size: 18px;
    }
    .value img{
        width: 102px;
        height: 102px;
        margin-top: 10px;
    }
    .value p{
        width: calc(100% - 60px);
        font-size: 12px;
        color: #BECFD2;
        line-height: 20px;
        padding: 0;
        margin: 0;
    }
    .friend{
        width: 100%;
        padding-top: 20px;
        background-image: url(../../static/images/friend-bg.png);
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .friend h3{
        text-align: center;
        font-size: 18px;
        margin-bottom: 30px;
        background: linear-gradient(299deg, #20A4EE 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .friend img{
        width: 100%;
        max-width: 800px;
    }
    .bg-pc{
        display: none;
    }
    .mobile{
        display: block;
    }
    .pc{
        display: none;
    }
    .copyright{
        font-size: 12px;
        text-align: center;
        display: none;
    }
    .top h1:first-child{
        margin-top: 60px;
    }
    @media screen and (min-width: 900px) {
        .root{
            padding-bottom: 0;
        }
        .copyright{
            display: block;
        }
        h1{
            margin-left: 160px;
            font-size: 40px;
            position: relative;
            z-index: 1
        }
        .desc{
            max-width: 600px;
            margin: 30px 160px 30px;
            z-index: 1;
            position: relative;
        }
        .top{
            position: relative;
            padding-top: 30px;
        }
        .buts-lay{
            position: static;
            height: 100%;
            margin-bottom: 50px;
        }
        .buts{
            flex-direction: row;
            height: 100% !important;
            margin: 50px 0 50px 130px
        }
        .buts-lay img{
            display: none;
        }
        .buts button{
            width: 140px;
            height: 40px;
        }
        .bg-pc{
            display: block;
            position: absolute;
            left: 900px;
            top: 0;
            max-width: 60%;
            max-height: 380px;
        }
        .share-lay{
            max-width: 600px;
            margin: 100px auto;
        }
        .share-lay img{
            width: 40px;
        }
        .share-lay span{
            display: block;
            font-size: 14px;
        }
        .value h4{
            font-size: 20px;
        }
        h3{
            font-size: 25px !important;
        }
        .value .content{
            justify-content: space-around;
        }
        .mobile{
            display: none;
        }
        .pc{
            display: block;
        }
        .earnings .content{
            width: calc(100% - 448px);
            padding: 40px 64px;
            flex-direction: row;
            border-radius: 10px;
            display: flex;
            align-items: center;
            position: relative;
            margin-bottom: 100px
        }
        .earnings .content:nth-last-child(2){
            flex-direction: row-reverse;
        }
        .earnings .content:nth-last-child(2) div{
            width: 100%;
            margin-left: 0;
            margin-right: 128px;
        }
        .earnings .content:nth-last-child(2) img{
            margin-right: 0;
        }
        .earnings .content img{
            margin-right: 64px;
            position: absolute;
            top: 0;
        }
        .earnings .content div{
            display: flex;
            flex-direction: column;
            margin-left: 128px;
        }
        h4{
            margin: 0;
            margin-bottom: 10px;
        }
        .earnings .content p{
            margin: 0;
            padding: 0;
        }
        .value .content-lay{
            width: calc(100% - 320px);
            max-width: 1500px;
            margin: 50px auto 0;
            display: flex;
            flex-wrap: wrap;
        }
        .value .content{
            width: calc(50% - 20px);
            max-width: 600px;
            padding: 30px 0 40px;
        }
        .friend{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .friend img{
            width: calc(100% - 320px);
            margin: 0 auto;
        }

    }
    @media screen and (max-width: 540px) {
        .value img{
            width: 80px;
            height: 80px;
            margin-top: 0;
        }
    }
    @media screen and (max-width: 440px) {
        .value img{
            width: 40px;
            height: 40px;
            margin-top: 0;
        }
    }
</style>
